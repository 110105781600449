// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}




















































































































































































.form-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  position: relative;

  &.password-show {
    .form-item-eye {
      &::before {
        opacity: 0;
      }
    }
  }

  .vdp-datepicker {
    input {
      padding-right: 28px;
      background-image: url("/img/icon/calendar.svg");
      background-repeat: no-repeat;
      background-position: right 6px top 15px;
    }
  }

  &.invalid {
    input {
      border: 1px solid #ff7b7b !important;
      background: #fff2f2 !important;
      &:focus,
      &:hover {
        border: 1px solid #ff7b7b !important;
      }
    }
  }

  &.required {
    .input-wrap {
      input {
        padding: 0 25px 0 12px;
      }

      &::after {
        content: "";
        position: absolute;
        right: 8px;
        top: 15px;
        border: solid #6f61e9;
        border-width: 0 2px 2px 0;
        width: 8px;
        height: 13px;
        opacity: 0;
        transform: rotate(45deg);
        filter: drop-shadow(0 0 15px rgba(0, 0, 0, 1));
      }
    }
  }

  &.valid {
    .input-wrap {
      background: #fff2f2;

      &::after {
        opacity: 1;
      }
    }
  }

  & + .form-item {
    margin-top: 10px;
  }

  label {
    width: calc(50% - 5px);
    font-size: 14px;
    color: #808080;

    .tooltip {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      span {
        position: absolute;
      }
    }
  }

  &-normal {
    label {
      width: calc(50% - 5px);
    }

    .input-wrap {
      width: calc(50% - 5px);
    }

    .input-wrap {
      margin-left: 10px;
    }
  }

  &-max {
    .input-wrap {
      width: 100%;
    }

    .input-wrap {
      margin: 0;
    }
  }

  &-top {
    flex-wrap: wrap;

    label {
      width: 100%;
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 16px;
      color: #000;
    }

    .input-wrap {
      width: 100%;
    }
  }

  .input-wrap {
    position: relative;

    input:focus + .input-wrap__tooltip,
    input:hover + .input-wrap__invalidity-info {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &__tooltip,
    &__invalidity-info {
      position: absolute;
      max-width: 270px;
      padding: 15px;
      left: 15px;
      top: calc(50% + 15px);
      font-size: 13px;
      color: #fff;
      mix-blend-mode: normal;
      background: rgba(111, 97, 233, 0.8);
      border-radius: 4px;
      backdrop-filter: blur(4px);
      transform: translate3d(0, 30px, 0);
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease;
      transition-property: opacity, visibility, transform;
      z-index: 10;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 30px;
        transform: translateY(-100%);
        border-bottom: 7px solid rgba(111, 97, 233, 0.8);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        backdrop-filter: blur(4px);
      }
    }

    &__invalidity-info {
      min-width: 317.5px;
      color: #333;
      border: 1px solid #ff7b7b;
      background: #fff2f2;
      &::before {
        content: "";
        border-bottom: 7px solid #ff7b7b;
        backdrop-filter: none;
      }
    }
  }

  .form-item-eye {
    position: absolute;
    width: 15px;
    height: 10px;
    top: 50%;
    margin-top: -4px;
    right: 15px;
    cursor: pointer;
    background: url("/img/icon/eye.svg") no-repeat center/cover;

    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 1px;
      top: 4px;
      left: 0px;
      border-bottom: 1px solid #5a5a5a;
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    padding: 0 12px;
    transition: 0.3s ease;
    background: #fff;
    border: 1px solid #e0dfee;
    transition-property: border;

    &:focus,
    &:hover {
      border: 1px solid #6f61e9;
    }

    &[disabled] {
      background: #f6f6f8;
      border: 1px solid transparent;

      &:focus,
      &:hover {
        border: 1px solid transparent;
      }
    }
  }

  &-password {
    input {
      padding-right: 35px;
    }
  }

  &-min {
    .input-wrap {
      margin-left: 10px;
      width: 34%;
    }
  }
}

.error-massage {
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #f5383b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 51%;
}

.form-item-control-panel {
  position: absolute;
  right: calc(0px + 10px);
  .form-item-fill-by-inn-button {
    padding: 0 10px;
    height: 50px;
    width: calc(100% + 10px);
  }
}
