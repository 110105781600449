// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}

































































.form-item {
  .label {
    width: calc(50% - 5px);
    font-size: 14px;
    color: #808080;
    & + .form-item-radio {
      margin-left: 10px;
      label {
        position: relative;
        display: flex;
        align-items: center;
        .form-item-radio__text {
          &:after,
          &::before {
            display: none;
          }
        }
        .form-item-radio__radiobox {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          background: #fff;
          border: 1px solid #e0dfee;
          box-sizing: border-box;
          border-radius: 50%;
          &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 8px;
            height: 8px;
            margin: -4px 0 0 -4px;
            border-radius: 50%;
            background: #6f61e9;
            transition: opacity 0.2s ease;
            opacity: 0;
          }
        }
      }
      input {
        display: none;
        width: 16px;
        height: 16px;
        cursor: pointer;
        &[disabled] {
          & ~ .form-item-radio__radiobox {
            background: #f6f6f8;
          }
        }
        &:checked ~ .form-item-radio__radiobox:before {
          opacity: 1;
        }
      }
      .form-item-radio__text {
        margin-left: 5px;
        display: inline-block;
        padding: 0;
        border: none;
        width: auto;
        height: auto;
        font-size: 14px;
        color: #333;
        font-weight: 400;
      }
    }
  }
  &-radio {
    display: flex;
    justify-content: flex-start;
    label {
      width: auto;
      cursor: pointer;
      & + label {
        margin-left: 15px;
      }
    }
    input {
      width: 0;
      height: 0;
      display: none;
      &:checked {
        & ~ .form-item-radio__text {
          border: 1px solid #6f61e9;
          color: #333;
          &::after {
            opacity: 1;
          }
        }
      }
    }
    &__text {
      position: relative;
      display: flex;
      align-items: center;
      width: 200px;
      height: 55px;
      padding: 5px 15px 5px 42px;
      background: #fff;
      box-sizing: border-box;
      border: 1px solid #e0dfee;
      color: #e0dfee;
      border-radius: 6px;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      transition: 0.2s ease;
      transition-property: border, color;
      &:after,
      &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
      }
      &::before {
        border: 1px solid #e0dfee;
        width: 16px;
        height: 16px;
        left: 15px;
        top: 50%;
        margin-top: -8px;
      }
      &:after {
        width: 8px;
        height: 8px;
        left: 20px;
        top: 50%;
        margin-top: -3px;
        background: #6f61e9;
        opacity: 0;
      }
    }
  }
  &.invalid {
    .form-item-radio__radiobox {
      border: 1px solid #ff7b7b !important;
      background: #fff2f2 !important;
    }
  }
}
