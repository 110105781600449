// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}












































































.form-item {
  &.invalid {
    textarea {
      background: #fff2f2;
      &:focus,
      &:hover {
        border: 1px solid #ff7b7b;
      }
    }
  }
  &-textarea {
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;
    text-align: left;
    & + .form-item {
      margin-top: 10px;
    }
    label {
      width: calc(50% - 5px);
      font-size: 14px;
      color: #808080;
      margin-top: 10px;
      margin-right: 10px;
    }
    textarea {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #e0dfee;
      border-radius: 4px;
      font-size: 14px;
      line-height: 22px;
      color: #333;
      padding: 15px 12px;
      resize: none;
      transition-property: border;
      &:focus,
      &:hover {
        border: 1px solid #6f61e9;
      }
      &[disabled] {
        background: #f6f6f8;
        border: 1px solid transparent;
        &:focus,
        &:hover {
          border: 1px solid transparent;
        }
      }
    }
  }
  .textarea-wrap{
    position: relative;
    width: calc(50% - 5px);
    height: 80px !important;
    
    &:hover .form-item__invalidity-info {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &__invalidity-info {
    position: absolute;
    min-width: 317.5px;
    padding: 15px;
    left: 15px;
    top: calc(50% + 35px);
    font-size: 13px;
    color: #333;
    mix-blend-mode: normal;
    background: #fff2f2;
    border: 1px solid #ff7b7b;
    border-radius: 4px;
    backdrop-filter: blur(4px);
    transform: translate3d(0, 30px, 0);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease;
    transition-property: opacity, visibility, transform;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 30px;
      transform: translateY(-100%);
      border-bottom: 7px solid #ff7b7b;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
}

.error-massage {
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #f5383b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 51%;
}
