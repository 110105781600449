// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}




























































































































































































































































































































































































.item-document {
  position: relative;
  width: 315px;
  height: 150px;
  border: 1px dashed #e0dfee;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  .item-document--status {
    position: absolute;
    display: flex;
    width: 25px;
    height: 25px;
    top: 10px;
    left: -10px;
    transition: opacity 0.25s ease;
    border-radius: 50%;
    z-index: 1;
    svg {
      margin: auto;
    }
  }
  &.valid {
    .item-document__img {
      &:not(.loaded) .item-document--status {
        opacity: 1;
        background: #10b487;
      }
    }
  }
  &.invalid {
    .item-document__img {
      &:not(.loaded) .item-document--status {
        opacity: 1;
        background: #f5383b;
      }
    }
  }
  &__img {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 80px;
    max-height: 100%;
    [aria-labelledby="loading"] {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -25px;
      &.fade-enter-active {
        transition: opacity 0.3s ease-in;
      }

      &.fade-enter-to {
        opacity: 1;
      }

      &.fade-enter {
        opacity: 0;
      }
    }
    img {
      position: relative;
      &.fade-enter-active {
        transition: opacity 0.2s ease-in;
      }

      &.fade-enter-to {
        opacity: 1;
      }

      &.fade-enter {
        opacity: 0;
      }
    }
  }
  &-content {
    width: 148px;
    height: 100%;
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 14px;
      line-height: 20px;
      color: #000;
      span {
        font-weight: 600;
      }
    }
    .descr {
      font-size: 10px;
      line-height: 15px;
      color: #000;
      max-height: 60px;
      word-wrap: break-word;
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          font-size: 12px;
          line-height: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .upload {
      margin-top: auto;
      cursor: pointer;
      input {
        display: none;
      }
      span {
        font-size: 14px;
        line-height: normal;
        color: #6f61e9;
        text-decoration: none;
      }
    }
    .download {
      margin-top: auto;
      font-size: 14px;
      line-height: normal;
      color: #6f61e9;
      text-decoration: none;
    }
    .watch {
      margin-top: auto;
      font-size: 14px;
      line-height: normal;
      color: #6f61e9;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .tooltip {
    position: absolute;
    width: 182px;
    top: 50%;
    transition: 0.5s ease;
    border-radius: 4px;
    transition-property: transform, opacity;
    background: #f87375;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    z-index: 1;
    &.right {
      right: 0;
      transform: translate(108%, -50%);
    }
    &.left {
      left: 0;
      transform: translate(-108%, -50%);
    }
    span {
      display: block;
      & + span {
        margin-top: 5px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -6px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
    }
    &.right:before {
      border-right: 7px solid #f87375;
      left: -5px;
    }
    &.left:before {
      right: -5px;
      border-left: 7px solid #f87375;
    }
    &.left-enter-active,
    &.left-leave-active {
      transform: translate(-108%, -50%);
    }
    &.left-enter,
    &.left-leave-to {
      opacity: 0;
      transform: translate(-180%, -50%);
    }
    &.right-enter-active,
    &.right-leave-active {
      transform: translate(108%, -50%);
    }
    &.right-enter,
    &.right-leave-to {
      opacity: 0;
      transform: translate(180%, -50%);
    }
  }
}
.ie {
  .choice-signature {
    .choice-signature-error {
      margin-top: 15px;
    }
    button {
      margin-top: 20px;
    }
  }
}
.foto-modal {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.webcam-buttons-controls {
  display: flex;      
  flex-direction: column;
  align-items: center;
  &-row{
    flex-direction:row;  
  }
}
.webcam-buttons-controls-row-child {
  margin-top: 10px;
}
.webcam-buttons-controls-row-child:first-child {
  margin-right: 20px; 
}
.no-camera-warning {
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
}
.no-picture-warning {
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  color: #333;
  margin-bottom: 10px;
  margin-top: 10px;
}
.webcam-select-item {
  display: flex;
  height: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
